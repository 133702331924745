import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'
import { initializeApp } from "firebase/app";
import UUID from "vue-uuid";



Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(UUID);

const firebaseConfig = {
  apiKey: "AIzaSyDR026tF-xYRJ8J2_9PJKNV3kqQxR5_ekg",
  authDomain: "upload-credentials.firebaseapp.com",
  projectId: "upload-credentials",
  storageBucket: "upload-credentials.appspot.com",
  messagingSenderId: "469293094774",
  appId: "1:469293094774:web:047cfa0f742f8885668c57"
};
initializeApp(firebaseConfig);



new Vue({
  router,
  store,
  vuetify,
  Vuelidate,
  render: h => h(App)
}).$mount('#app')
