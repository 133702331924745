<template>

<h2>soy el home</h2>
</template>

<script>
import usersData from "../prom.json"


  export default {
    name: 'HomeView',
    data() {
      return {
        users: usersData.distrital,
      };
    },

    components: {
     
    },
  }
</script>
