<template>
  <v-app>
    <v-app-bar
      app
      color=""
      dark
      
    >
      <v-spacer></v-spacer>
    <a @click="cerrarSesion" v-if="isActive"><v-icon  color="blue darken-3">mdi-logout</v-icon>Cerrar Sesión</a>
      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods:{
    ...mapActions(['cerrarSesion', 'readUser'])
  },
  computed: {
    ...mapGetters(['isActive'])
  },
  beforeCreate() {

  },
  mounted(){
    
  },
  created(){

    this.readUser();
  }
};
</script>
