import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: HomeView,
    meta: {requireAuth: true}
  },
  {
    path: '/jdistrito',
    name: 'jdistrito',
    component: () => import( '../views/Distrital.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/general',
    name: 'general',
    component: () => import( '../views/General.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/coordinador',
    name: 'coordinador',
    component: () => import( '../views/Coordinador.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/promotor',
    name: 'promotor',
    component: () => import( '../views/Promotor.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/login',
    name: 'loginview',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '*',
    redirect: '/login'
    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async(to, from, next) => {
const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
if(rutaProtegida && !store.state.usuario.status){
 router.push({path: '/login', name: 'loginview',})
next()
    
  }else{
  next()
  }
})



export default router
