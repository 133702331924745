import Vue from 'vue'
import Vuex from 'vuex'
// import data from '@/prom.json'
import createPersistedState from 'vuex-persistedstate';
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
 
    URL: "https://estructura-interna-backend-production.up.railway.app",
    usuario: {
      status: false,
      data: null,
      token: null
    },

    
    subdistrito: null,
    coordinadores: null,
    promotores: null,
    promovidos: null

  },
  getters: {
  },
   plugins: [createPersistedState()],
  mutations: {
    SET_USER(state, data) {
      state.usuario.status = true;
      state.usuario.data = data.data;
      state.usuario.token = data.token;
    },
    LOG_OUT(state) {
      state.usuario.status = false;
      state.usuario.data = null;
      state.usuario.token = null;
    },
  },
  actions: {
    fetchUser({ commit }, data){
      
      if (data!==null) {
      localStorage.setItem('usuario', JSON.stringify(data)) 
        commit("SET_USER", 
            data
        );
      } else {
        commit("SET_USER", null);        
      }
    },
    readUser({commit}){
      const usuariolocal = localStorage.getItem('usuario')
      if(usuariolocal){
        let usuario = JSON.parse(usuariolocal)
        
        commit('SET_USER', usuario)
       
        switch (usuario.data.user.role) {
          case 'Admin':
              router.push('/admin');
              break;
          case 'JefeDeDistrito':
              router.push('/jdistrito');
              break;
          case 'Coordinador':
              router.push('/coordinador');
              break;
          case 'Promotor':
              router.push('/promotor');
              break;
          default:
              break;
      }
      } else {
        commit("LOG_OUT");
      }
    },
    cerrarSesion({commit}){
      commit("LOG_OUT");
      router.push('/login')
      localStorage.removeItem('usuario')
      localStorage.removeItem('vuex') 
    }
  },
  modules: {
  },
  getters: {
    isActive: state => !!state.usuario.status
  }
})
